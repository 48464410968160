<template>
  <div>
    <Header />
    <div class="content-fist">
      <button-wpp />
      <section class="container mt-5 margin_bottom">
        <div class="banner width-100">
          <div class="content">
            <h1>¿Quieres crear y vender tours de manera eficiente?</h1>
            <h2>
              Con nuestra plataforma <strong>Guiia Tour</strong>, puedes hacerlo
              en unos pocos clics. ¡Descubrelo obteniendo la prueba gratuita
              ahora!
            </h2>
            <router-link class="btn-nav btn-register" to="/registro"
              >Pruébalo 1 mes gratis</router-link
            >
          </div>
          <div class="imagen">
            <img class="img-1" src="@/assets/images/pc.png" alt="Guiia Tour" />
          </div>
        </div>
      </section>
      <section class="container-bg mt-5 margin_bottom pt-5 pb-5">
        <div class="container">
          <h1 class="title">
            Optimiza tu negocio de tours con nuestra plataforma
            <strong>Guiia Tour</strong>
          </h1>
          <div class="banner-2">
            <div class="content">
              <h1>El poder de llevar tu negocio fácilmente</h1>
              <h2>
                Simplifica la gestión de tours con nuestra plataforma intuitiva.
                Desde la creación hasta la venta de tickets, ¡lo tenemos
                cubierto!
              </h2>
              <router-link class="btn-nav btn-login" to="/registro"
                >Pruébalo 1 mes gratis</router-link
              >
            </div>
            <div class="imagen">
              <b-embed
                type="iframe"
                aspect="16by9"
                src="https://www.youtube.com/embed/9YxaKa_i_H4?si=8P3oR9bOsNENO8gu"
                allowfullscreen
              ></b-embed>
            </div>
          </div>
        </div>
      </section>
      <section class="container mt-5 margin_bottom">
        <h1 class="title text_black">
          Haz que la organización de tours sea un proceso sin complicaciones
        </h1>
        <div class="content-cards">
          <div class="card-home">
            <img src="@/assets/images/upload.png" alt="Guiia Tour" />
            <h1 class="title-card">100% en la nube</h1>
            <p>
              Accede desde la computadora, tablet o celular que esté a tu
              alcance.
            </p>
          </div>
          <div class="card-home">
            <img src="@/assets/images/chat.png" alt="Guiia Tour" />
            <h1 class="title-card">Con soporte gratis</h1>
            <p>Recibe asesoría ilimitada por nuestro equipo.</p>
          </div>
          <div class="card-home">
            <img src="@/assets/images/verified.png" alt="Guiia Tour" />
            <h1 class="title-card">Información segura</h1>
            <p>
              Toda la Información se encuentra segura y solo tu tendrás acceso.
            </p>
          </div>
        </div>
      </section>
      <section class="container-bg mt-5 margin_bottom pt-5 pb-5">
        <div class="container text-center">
          <h1 class="title">
            Prueba el poder de <strong>Guiia Tour</strong> durante 1 mes gratis
          </h1>
          <div class="banner-2">
            <div class="content">
              <h2>
                "¡No esperes más para llevar tu negocio de tours al siguiente
                nivel! Únete ahora y disfruta de un mes completamente gratis.
              </h2>
            </div>
          </div>
          <router-link class="btn-nav btn-login" to="/registro"
            >Empieza 1 mes gratis</router-link
          >
        </div>
      </section>
      <section class="container mt-5 margin_bottom">
        <h1 class="title text_black">
          ¿Por que usar nuestra plataforma <strong>Guiia Tour</strong>?
        </h1>
        <div class="list-items">
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>
              Simplifica todo el proceso de gestión de tours.
            </h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>
              Está diseñada para ser intuitiva y fácil de
              usar, lo que significa que puedes empezar a gestionar tus tours
              rápidamente, incluso si no tienes experiencia técnica previa.
            </h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>Crea y gestiona tus tours de manera sencilla.</h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>
              Al crear una nueva venta, automaticamente se le envía un mensaje
              de whatsapp al cliente con el detalle de la venta.
            </h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>
              Genera planillas en formato PDF con el listado de los pasajeros.
            </h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>Realiza la liquidación de cada tour.</h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>Mueve tus ventas a otro tour.</h1>
          </div>
          <div class="item">
            <img src="@/assets/images/checked.png" alt="Guiia Tour" />
            <h1>Mueve tus ventas a otra empresa.</h1>
          </div>
        </div>
        <div class="mt-4">
          <router-link class="btn-nav btn-register" to="/registro"
            >Pruébalo 1 mes gratis</router-link
          >
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import ButtonWpp from "@/components/buttons/ButtonWpp";
import {BEmbed} from "bootstrap-vue"
export default {
  components: {
    Header,
    Footer,
    ButtonWpp,
    BEmbed,
  },
  mounted() {
    window.scroll(0, 0);
  },
};
</script>

<style lang="css">
@import url("./home.css");
</style>
