var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"content-fist"},[_c('button-wpp'),_c('section',{staticClass:"container mt-5 margin_bottom"},[_c('div',{staticClass:"banner width-100"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("¿Quieres crear y vender tours de manera eficiente?")]),_vm._m(0),_c('router-link',{staticClass:"btn-nav btn-register",attrs:{"to":"/registro"}},[_vm._v("Pruébalo 1 mes gratis")])],1),_vm._m(1)])]),_c('section',{staticClass:"container-bg mt-5 margin_bottom pt-5 pb-5"},[_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticClass:"banner-2"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("El poder de llevar tu negocio fácilmente")]),_c('h2',[_vm._v(" Simplifica la gestión de tours con nuestra plataforma intuitiva. Desde la creación hasta la venta de tickets, ¡lo tenemos cubierto! ")]),_c('router-link',{staticClass:"btn-nav btn-login",attrs:{"to":"/registro"}},[_vm._v("Pruébalo 1 mes gratis")])],1),_c('div',{staticClass:"imagen"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":"https://www.youtube.com/embed/9YxaKa_i_H4?si=8P3oR9bOsNENO8gu","allowfullscreen":""}})],1)])])]),_vm._m(3),_c('section',{staticClass:"container-bg mt-5 margin_bottom pt-5 pb-5"},[_c('div',{staticClass:"container text-center"},[_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"btn-nav btn-login",attrs:{"to":"/registro"}},[_vm._v("Empieza 1 mes gratis")])],1)]),_c('section',{staticClass:"container mt-5 margin_bottom"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"btn-nav btn-register",attrs:{"to":"/registro"}},[_vm._v("Pruébalo 1 mes gratis")])],1)])],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" Con nuestra plataforma "),_c('strong',[_vm._v("Guiia Tour")]),_vm._v(", puedes hacerlo en unos pocos clics. ¡Descubrelo obteniendo la prueba gratuita ahora! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imagen"},[_c('img',{staticClass:"img-1",attrs:{"src":require("@/assets/images/pc.png"),"alt":"Guiia Tour"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title"},[_vm._v(" Optimiza tu negocio de tours con nuestra plataforma "),_c('strong',[_vm._v("Guiia Tour")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container mt-5 margin_bottom"},[_c('h1',{staticClass:"title text_black"},[_vm._v(" Haz que la organización de tours sea un proceso sin complicaciones ")]),_c('div',{staticClass:"content-cards"},[_c('div',{staticClass:"card-home"},[_c('img',{attrs:{"src":require("@/assets/images/upload.png"),"alt":"Guiia Tour"}}),_c('h1',{staticClass:"title-card"},[_vm._v("100% en la nube")]),_c('p',[_vm._v(" Accede desde la computadora, tablet o celular que esté a tu alcance. ")])]),_c('div',{staticClass:"card-home"},[_c('img',{attrs:{"src":require("@/assets/images/chat.png"),"alt":"Guiia Tour"}}),_c('h1',{staticClass:"title-card"},[_vm._v("Con soporte gratis")]),_c('p',[_vm._v("Recibe asesoría ilimitada por nuestro equipo.")])]),_c('div',{staticClass:"card-home"},[_c('img',{attrs:{"src":require("@/assets/images/verified.png"),"alt":"Guiia Tour"}}),_c('h1',{staticClass:"title-card"},[_vm._v("Información segura")]),_c('p',[_vm._v(" Toda la Información se encuentra segura y solo tu tendrás acceso. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title"},[_vm._v(" Prueba el poder de "),_c('strong',[_vm._v("Guiia Tour")]),_vm._v(" durante 1 mes gratis ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-2"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v(" \"¡No esperes más para llevar tu negocio de tours al siguiente nivel! Únete ahora y disfruta de un mes completamente gratis. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title text_black"},[_vm._v(" ¿Por que usar nuestra plataforma "),_c('strong',[_vm._v("Guiia Tour")]),_vm._v("? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-items"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v(" Simplifica todo el proceso de gestión de tours. ")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v(" Está diseñada para ser intuitiva y fácil de usar, lo que significa que puedes empezar a gestionar tus tours rápidamente, incluso si no tienes experiencia técnica previa. ")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v("Crea y gestiona tus tours de manera sencilla.")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v(" Al crear una nueva venta, automaticamente se le envía un mensaje de whatsapp al cliente con el detalle de la venta. ")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v(" Genera planillas en formato PDF con el listado de los pasajeros. ")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v("Realiza la liquidación de cada tour.")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v("Mueve tus ventas a otro tour.")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/checked.png"),"alt":"Guiia Tour"}}),_c('h1',[_vm._v("Mueve tus ventas a otra empresa.")])])])
}]

export { render, staticRenderFns }